<template>
  <div style="margin: 20px">
    <div class="flex items-center mb-4">
      <i
        class="el-icon-arrow-left font-bold text-xl cursor-pointer"
        style="color: #999999"
        @click="goBack"
      />
      <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">
        Standards List
      </p>
    </div>
    <div class="flex flex-wrap">
      <div
        v-for="(item, index) in dataList"
        :key="index"
        class="activity-idea my-4"
      >
        <Standard :info="item" :index="index" @send="sendStandard(item)" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getActivityOptionStandards,
  saveStandardApplication,
} from "../../api/eae";
import Standard from "../../components/StandardItem.vue";
import { getUserId } from "../../utils/store";

export default {
  name: "index",
  components: {
    Standard,
  },
  data() {
    return {
      dataList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getActivityOptionStandards(this.$route.query.id).then((res) => {
        this.dataList = res.data.data;
      });
    },
    goBack() {
      this.$router.push({
        path: "/home/reviewEntries",
      });
    },
    sendStandard(row) {
      let standard_id = row._id.$id;
      let option_id = row.option_id;
      let activity_id = this.$route.query.id;
      let entry_id = this.$route.query.entryId;
      saveStandardApplication(
        getUserId(),
        option_id,
        standard_id,
        activity_id,
        entry_id
      ).then((res) => {
        this.$message.success("Send Success");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-idea {
  width: calc(50% - 20px);
  margin: 16px 10px;
}
</style>