<template>
  <div
    class="activity-container p-6 bg-white flex items-center justify-between"
  >
    <div class="flex">
      <div class="sort font-bold mr-6">{{ index + 1 }}</div>
      <div class="activity-info">
        <p class="activity-name text-base font-bold mb-4">
          {{ info.title }}
        </p>
        <div class="activity-desc mb-4">
          <p class="text-sm font-bold">
            {{ info.desc }}
          </p>
        </div>
        <div>
          <el-button size="small" type="primary" @click="handleSend"
            >Send Standards Application</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StandardItem",
  props: {
    info: Object,
    index: Number,
  },
  methods: {
    handleView() {
      this.$emit("view");
    },
    handleSend() {
      this.$emit("send");
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-container {
  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }
  .activity-info {
    .activity-name {
      color: #333333;
    }
    .activity-time {
      color: #ff8900;
      // span {
      //   color: #999999;
      // }
      p {
        margin-right: 45px;
      }
    }
    .activity-location {
      color: #ff8900;
      span {
        color: #999999;
      }
      p {
        margin-right: 60px;
      }
    }
    .activity-desc {
      color: #999999;
    }
  }
}
</style>
